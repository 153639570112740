import styled, { css } from 'styled-components';
import React from 'react';
import { useRouter } from 'next/router';
import { headerLinks } from 'src/constants/global';
import Link from 'next/link';
import { FontIcon } from '@shapegames/quick-draw-ui';

interface HeaderNavigationProps {
  variant?: 'vertical' | 'horizontal';
}

function HeaderNavigation({ variant = 'horizontal' }: HeaderNavigationProps) {
  const { pathname } = useRouter();

  return (
    <HeaderNavigationCss vertical={variant === 'vertical'}>
      {headerLinks.map((headIt, idx) => (
        <Link passHref href={headIt.href} key={`header-link-${idx}`}>
          <TestItemCss active={pathname === headIt.href}>
            <FontIconCss name={headIt.icon} />
            {headIt.text}
          </TestItemCss>
        </Link>
      ))}

      <CustomLinkCss
        href="https://www.jackentertainment.com/"
        target="_blank"
        rel="noreferrer"
      >
        JACK Casinos
      </CustomLinkCss>
      <CustomLinkCss
        href="https://playjack.com/?utm_medium=betjack&utm_source=betjackcom&utm_campaign=homepage"
        target="_blank"
        rel="noreferrer"
      >
        PlayJACK Casino
      </CustomLinkCss>
      <CustomLinkCss href="/authors">Authors</CustomLinkCss>

      <CustomLinkCss
        href="https://www.jackentertainment.com/responsible-gaming"
        target="_blank"
        rel="noreferrer"
      >
        Responsible gaming
      </CustomLinkCss>

      <GamblingProblemCss>Gambling Problem?</GamblingProblemCss>
      <GamblingProblemCss byline>Call 1-800-522-4700</GamblingProblemCss>
    </HeaderNavigationCss>
  );
}

const HeaderNavigationCss = styled.nav<{ vertical?: boolean }>`
  display: flex;
  height: 100%;
  color: ${p => p.theme.colors.gray700};
  padding-left: ${p => p.theme.sizes.spacing10};

  & > a:not(:last-child) {
    margin-bottom: ${p => (p.vertical ? p.theme.sizes.spacing6 : '0')};
  }

  ${p =>
    p.vertical &&
    `
    display: flex;
    flex-direction: column;
  `}
`;

const headerNavigationItemCss = css<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  color: ${p =>
    p.active
      ? p.theme.platformHeaderNavigation.navItemActiveColor
      : p.theme.platformHeaderNavigation.navItemColor};
  font-size: ${p => p.theme.platformHeaderNavigation.navItemFontSize};
  border: 0;
  ${p => p.theme.platformHeaderNavigation.navItemFont}

  ${p => p.theme.breakpoints.lg} {
    height: 100%;
    justify-content: center;
    margin-right: ${p => p.theme.sizes.spacing8};

    &:after {
      content: '';
      display: ${p => (p.active ? 'block' : 'none')};
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: ${p =>
        p.theme.platformHeaderNavigation.navItemActiveIndicatorHeight};
      border-radius: ${p =>
        p.theme.platformHeaderNavigation.navItemActiveIndicatorBorderRadius};
      background: ${p =>
        p.theme.platformHeaderNavigation.navItemActiveIndicatorColor};
    }
  }
`;

const TestItemCss = styled.a<{ active?: boolean }>`
  ${headerNavigationItemCss}
`;

const HeaderNavigationLinkCss = styled.a<{ selected?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: #b4b4b4;
  ${p =>
    p.selected &&
    `
      color: ${p.theme.colors.black};
  `}
`;

const FontIconCss = styled(FontIcon)`
  font-size: ${p => p.theme.sizes.iconSizeSmall};
  margin-right: ${p => p.theme.sizes.spacing2};
`;

const CustomLinkCss = styled(HeaderNavigationLinkCss)`
  ${p => p.theme.platformHeaderNavigation.navItemFont}

  ${p => p.theme.breakpoints.lg} {
    display: none;
  }
`;

const GamblingProblemCss = styled.span<{ byline?: boolean }>`
  ${p => p.theme.platformHeaderNavigation.navItemFont}
  margin-top: ${p => (p.byline ? p.theme.sizes.spacing1 : '0')};
  color: ${p => p.theme.colors.primary500};
  font-size: ${p =>
    p.byline
      ? p.theme.typography.textSm
      : p.theme.platformHeaderNavigation.navItemFontSize};

  ${p => p.theme.breakpoints.lg} {
    display: none;
  }
`;
export default HeaderNavigation;
