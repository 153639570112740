import React from 'react';
import styled from 'styled-components';
import { LEFT_MENU_WIDTH } from 'src/constants/layout';
import VerticalNavigationMenu from '@shapegames/quick-draw-ui/lib/VerticalNavigationMenu';
import MenuList from './MenuList';
import { Tag } from 'src/services/articles';

interface DesktopNavigationMenuProps {
  tags: Tag[];
}

function DesktopNavigationMenu({ tags }: DesktopNavigationMenuProps) {
  const popularItems = tags.filter(tag => !tag.icon);
  const otherItems = tags.filter(tag => !!tag.icon);

  return (
    <DesktopNavigationMenuContainerCss>
      <DesktopNavigationMenuCss title="Popular Topics">
        <MenuList type="desktop" tags={popularItems} iconFallback={false} />
      </DesktopNavigationMenuCss>
      <DesktopNavigationMenuCss title="All Sports">
        <MenuList type="desktop" tags={otherItems} iconFallback={false} />
      </DesktopNavigationMenuCss>
      <DesktopNavigationMenuCss title="Other">
        <li>
          <CustomLinkCss
            href="https://www.jackentertainment.com/"
            target="_blank"
            rel="noreferrer"
          >
            JACK Casinos
          </CustomLinkCss>
        </li>
        <li>
          <CustomLinkCss
            href="https://playjack.com/?utm_medium=betjack&utm_source=betjackcom&utm_campaign=homepage"
            target="_blank"
            rel="noreferrer"
          >
            PlayJACK Casino
          </CustomLinkCss>
        </li>
        <li>
          <CustomLinkCss href="/authors">Authors</CustomLinkCss>
        </li>
        <li>
          <CustomLinkCss
            href="https://www.jackentertainment.com/responsible-gaming"
            target="_blank"
            rel="noreferrer"
          >
            Responsible Gaming
          </CustomLinkCss>
        </li>
      </DesktopNavigationMenuCss>

      <DesktopNavigationCustomMenuCss fullHeight title="Gambling Problem?">
        <li>
          <CustomMenuLabelCss>Call 1-800-522-4700</CustomMenuLabelCss>
        </li>
      </DesktopNavigationCustomMenuCss>
    </DesktopNavigationMenuContainerCss>
  );
}

const DesktopNavigationMenuContainerCss = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  a,
  span {
    ${p => p.theme.platformHeaderNavigation.navItemFont}
  }
`;

const DesktopNavigationMenuCss = styled(VerticalNavigationMenu)<{
  fullHeight?: boolean;
}>`
  width: ${LEFT_MENU_WIDTH} !important;
  min-width: ${LEFT_MENU_WIDTH};
  padding-top: ${p => p.theme.sizes.spacing10};
  padding-left: ${p => p.theme.sizes.spacing10};

  ${p => (p.fullHeight ? `flex: 1;` : '')}

  ${p => p.theme.breakpoints.maxLg} {
    display: none;
  }
`;

const CustomLinkCss = styled.a`
  display: inline-block;
  color: ${p => p.theme.colors.white};
  padding: ${p => p.theme.sizes.spacing1} ${p => p.theme.sizes.spacing4};
  font-size: ${p => p.theme.typography.textSm};
  font-family: baseTheme.getFontStyle('primary', 'regular');
`;

const CustomMenuLabelCss = styled.span`
  display: inline-block;
  color: ${p => p.theme.colors.primary500};
  padding-top: ${p => p.theme.sizes.spacing1};
  padding-left: ${p => p.theme.sizes.spacing4};
  ${p => p.theme.platformHeaderNavigation.navItemFont}
  font-size: ${p => p.theme.typography.textSm};
`;

// Sorry this is very custom - requested by the client
// https://shapegames.atlassian.net/browse/JACK-1041
const DesktopNavigationCustomMenuCss = styled(DesktopNavigationMenuCss)`
  > div {
    color: ${p => p.theme.colors.primary500};
  }

  ul {
    margin: 0;
  }
`;

export default DesktopNavigationMenu;
