import styled from 'styled-components';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHamburgerMenu } from './HamburgerMenuContext';
import {
  ZI_HAMBURGER_MENU_OVERLAY,
  ZI_HAMRBUGER_MENU,
} from '../../constants/layout';
import { useLockBodyScroll } from '@shapegames/quick-draw-ui/lib/hooks/use-lock-body-scroll';

interface HamburguerMenuProps {
  children?: ReactNode;
}

function HamburguerMenu({ children }: HamburguerMenuProps) {
  const { visible, toggle } = useHamburgerMenu();
  const [hideOverlay, setHideOverlay] = useState(false);

  useLockBodyScroll(visible);

  useEffect(() => {
    // This effect adds a delay so the overlay animation can finish before
    // hiding the element
    if (!visible) {
      const timeout = setTimeout(() => {
        setHideOverlay(true);
      }, 510);
      return () => {
        clearTimeout(timeout);
      };
    }

    setHideOverlay(false);
  }, [visible]);

  function handleOverlayClick() {
    if (!visible) return;
    toggle();
  }

  return (
    <>
      <OverlayCss
        visible={visible}
        hideOverlay={hideOverlay}
        onClick={handleOverlayClick}
      />
      <HamburgerMenuCss open={visible}>{children}</HamburgerMenuCss>
    </>
  );
}
const OverlayCss = styled.div<{ visible?: boolean; hideOverlay?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${p => (p.hideOverlay ? -1 : ZI_HAMBURGER_MENU_OVERLAY)};
  background: transparent;
  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  ${p =>
    p.visible &&
    `
    background: rgba(0,0,0,0.6);
  `}
`;

const HamburgerMenuCss = styled.div<{ open?: boolean }>`
  position: absolute;
  transform-origin: 0% 0%;
  width: 300px;
  padding-top: 40px;
  height: 100vh;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  background: white;
  z-index: ${ZI_HAMRBUGER_MENU};

  ${p => p.theme.breakpoints.sm} {
    padding-top: 80px;
  }

  ${p =>
    p.open &&
    `
        transform: translateX(0);
    `}
`;

export default HamburguerMenu;
