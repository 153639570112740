import styled from 'styled-components';
import React from 'react';
import { Tag } from 'src/services/articles';
import { MAIN_BACKGROUND_COLOR } from 'src/constants/layout';
import HorizontalNavigationMenu from '@shapegames/quick-draw-ui/lib/HorizontalNavigationMenu';
import MenuList from './MenuList';

interface MobileNavigationMenuProps {
  tags: Tag[];
}

function MobileNavigationMenu(props: MobileNavigationMenuProps) {
  return (
    <HorizontalNavigationMenuCss>
      <MenuList iconFallback={true} type="mobile" tags={props.tags} />
    </HorizontalNavigationMenuCss>
  );
}

const HorizontalNavigationMenuCss = styled(HorizontalNavigationMenu)`
  background: ${MAIN_BACKGROUND_COLOR};
  margin-top: -1px !important;

  ${p => p.theme.breakpoints.lg} {
    display: none !important;
  }
`;

export default MobileNavigationMenu;
