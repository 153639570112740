import { HeaderLink } from './types';

export const PAGE_REVALIDATE_TIME = 60;
export const CLOUD_FARE_IMAGE_RESIZER_URL =
  'https://cms.betjack.com/image-resizing-2020';

export const WEBSITE_TITLE = 'betJACK News';
export const WEBSITE_DESCRIPTION = 'Fresh Sports News';

export const headerLinks: HeaderLink[] = [
  {
    text: 'Home',
    icon: 'icon-top-home',
    href: 'https://betjack.com/en/sports',
  },
  { text: 'Live', icon: 'icon-top-live', href: 'https://betjack.com/en/live' },
  {
    text: 'Leaderboard',
    icon: 'leaderboard',
    href: 'https://betjack.com/en/leaderboard',
  },
  {
    text: 'Promotions',
    icon: 'gift',
    href: 'https://betjack.com/en/f2p-promotions',
  },
  {
    text: 'News',
    icon: 'news',
    href: '/',
  },
  // {
  //   text: 'Authors',
  //   href: '/authors',
  // },
];
