import { serializeParams } from '@shapegames/utils/lib/helpers/serialize-params';
import { CLOUD_FARE_IMAGE_RESIZER_URL } from 'src/constants/global';
import { Crop, CropType } from 'src/services/articles/types/article';

type ImageSize = { height: number; width: number };

type InternalCropTypes = CropType | 'thumbnailCrop' | 'landscapeCoverCrop';

type MakeImageUrlArgs = Partial<{
  height: number;
  width: number;
  top: number;
  left: number;
  right: number;
  bottom: number;
}> & { image: string };

interface ImageUrlMakerArgs {
  /** The original image URL */
  imageUrl: string;
  /**
   * The crop type used to get the default sizes (width & height) from
   */
  cropType: InternalCropTypes;
  /** The crop object where the cropping parameters  will
   * be provided from.
   */
  crop?: Crop | null;
  /** If provieed it will override the crop sizes */
  sizes?: ImageSize;
}

export const mapCropsToImageSizes: Record<InternalCropTypes, ImageSize> = {
  squareCrop: {
    height: 474,
    width: 474,
  },
  landscapeCrop: {
    height: 303,
    width: 469,
  },
  landscapeWideCrop: {
    height: 360,
    width: 730,
  },
  landscapeCoverCrop: {
    height: 375,
    width: 760,
  },
  thumbnailCrop: {
    height: 60,
    width: 93,
  },
};

const makeImageCropUrl = (params: MakeImageUrlArgs) =>
  `${CLOUD_FARE_IMAGE_RESIZER_URL}/?${serializeParams(params)}`;

export const imageUrlMaker = ({
  imageUrl,
  cropType,
  crop,
  sizes,
}: ImageUrlMakerArgs) => {
  const { height, width } = sizes || mapCropsToImageSizes[cropType];

  const makeImageArgs: MakeImageUrlArgs = {
    height,
    width,
    image: imageUrl,
  };

  if (!crop) {
    return makeImageCropUrl(makeImageArgs);
  }

  // Crop v1
  const { topLeft, bottomRight } = crop;
  if (crop.bottomRight || crop.topLeft) {
    return makeImageCropUrl({
      ...makeImageArgs,
      top: topLeft?.y,
      left: topLeft?.x,
      bottom: bottomRight?.y,
      right: bottomRight?.x,
    });
  }
  // Crop v2
  else if (crop.offset) {
    return makeImageCropUrl({
      ...makeImageArgs,
      top: crop.offset.top,
      left: crop.offset.left,
      bottom: crop.offset.bottom,
      right: crop.offset.right,
    });
  }

  return imageUrl;
};
