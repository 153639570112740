import React from 'react';
import HorizontalNavigationMenu from '@shapegames/quick-draw-ui/lib/HorizontalNavigationMenu';
import VerticalNavigationMenu from '@shapegames/quick-draw-ui/lib/VerticalNavigationMenu';
import { Tag } from 'src/services/articles';
import Link from '../Link';

interface MenuListProps {
  type: 'desktop' | 'mobile';
  tags: Tag[];
  iconFallback?: boolean;
}

function MenuList({ tags, type, iconFallback }: MenuListProps) {
  const mapTypeToComponent = {
    desktop: VerticalNavigationMenu.Item,
    mobile: HorizontalNavigationMenu.Item,
  };
  const Item = mapTypeToComponent[type];

  return (
    <>
      {tags?.map((it, idx) => (
        <Item
          key={`menu-item-${idx}`}
          text={it.name}
          iconFallbackContent={iconFallback ? it.name.slice(0, 1) : ''}
          iconName={it.icon ? `icon-category-${it.icon}` : ''}
          renderItem={content => (
            <Link href={`/tags/${it.slug}`}>{content}</Link>
          )}
        />
      ))}
    </>
  );
}

export default MenuList;
